<script>
import draggable from 'vuedraggable'

export default {
	components: {
			draggable
	},
	props: {
		menuTerciario: {
			type: Array,
			required: false
		},
		errorForm: {
			type: Boolean,
			required: false
		}
	},
	computed: {
		dragOptions() {
			return {
				animation: 200,
				ghostClass: "ghost"
			};
		},
	},
	methods: {
		deleteMenuTertiary(index) {
			this.menuTerciario.splice(index, 1);
		}
	}
}
</script>
<template>
	<draggable 
		class="lw-list-drag lw-list-drag-scroll-max border mb-3 p-3" 
		style="cursor: auto;"
		:list="menuTerciario" 
		group="tertiary" 
		@start="drag = true"
		@end="drag = false"
		v-bind="dragOptions"
	>
		<p v-if="menuTerciario.length === 0" class="text-secondary text-opacity-75 text-center mb-0">
			No hay ningún menú terciario creado.
		</p>
		<template v-else>				
			<div v-for="(item, index) in menuTerciario" :key="item.id_elemento" class="lw-list-drag-container">
				<div class="lw-list-drag-item justify-content-between">
					<div class="d-flex align-items-center w-100 me-2">
						<div class="flex-shrink-0">
							<i class="fa-solid fa-grip-vertical lw-list-drag-item-icon me-2"></i>
						</div>
						<div class="flex-grow-1 lh-1 d-flex align-items-center w-100">
							<div class="d-flex align-items-center gap-3 w-100">
								<input 
									type="text" 
									v-model.trim="item.nombre_elemento"
									class="form-control input-custom input-custom-small input-custom-inset-shadow flex-grow-1" 
									:class="item.nombre_elemento === '' && errorForm ? 'is-invalid' : '' "
									placeholder="Nombre menú terciario"
								>
								<i class="fa-solid fa-angle-right"></i>
								<input 
									type="text" 
									v-model.trim="item.url_externa"
									class="form-control input-custom input-custom-small input-custom-inset-shadow flex-grow-1"
									:class="item.url_externa === '' && errorForm ? 'is-invalid' : '' " 
									placeholder="URL"
								>
							</div>
						</div>
					</div>
					<div class="d-flex align-items-center gap-2">
						<a 
							@click="deleteMenuTertiary(index)"
							class="lw-list-drag-item-btn-collapse"
							href="javascript:"
						>
							<i class="fa-regular fa-trash-can"></i>
						</a>
					</div>
				</div>
			</div>
		</template>

	</draggable>
</template>