<script>
import draggable from 'vuedraggable'
import MenuCustomPrincipal from '@/components/customization/menuCustomPrincipal.vue'
import { mapActions } from "vuex";

export default {
	components: {
		draggable,
		MenuCustomPrincipal
	},
	props: {
		menu: {
			type: Array,
			required: false
		},
		errorForm: {
			type: Boolean,
			required: false
		},
	},
	data() {
		return {
			error: 0
		}
	},
	computed: {
		dragOptions() {
			return {
				animation: 200,
				ghostClass: "ghost"
			};
		},
	},
	methods: {
		...mapActions("customizationModule", ["setSelectedMenuGeneral"]),

		addMenuPrincipal(item) {
			item.principal.push({
				id_elemento: Symbol(),
				nombre_elemento: '',
				url_externa: '',
				secundarios: []
			});
		},
		editMenuGeneral(item) {
			this.setSelectedMenuGeneral(item);
		},
		deleteMenuGeneral(index) {
			this.$emit('deleteMenuGeneral', index);
		}
	}
}
</script>

<template>
	<draggable class="lw-list-drag" style="cursor: auto;" :list="menu" group="general" @start="drag = true" @end="drag = false" v-bind="dragOptions">
		<div v-for="(item, index) in menu" :key="item.id_header" class="lw-list-drag-container">
			<div class="lw-list-drag-item justify-content-between">
				<div class="d-flex align-items-center">
					<div class="flex-shrink-0">
						<i class="fa-solid fa-grip-vertical lw-list-drag-item-icon me-2"></i>
					</div>
					<div class="flex-grow-1 lh-1 d-flex align-items-center">
						<a class="lw-list-drag-item-btn-collapse me-2 collapsed" href="javascript:" data-bs-toggle="collapse" :data-bs-target="`#collapse-general-${index}`">
							<i class="fa-solid fa-angle-down lw-list-drag-item-btn-collapse-icon"></i>
						</a>
						<span class="square-color-menu me-2" :style="`background-color: ${item.color_activo}`">
								</span> {{ item.nombre_header }}
					</div>
				</div>
				<div class="d-flex align-items-center gap-2">
					<a @click="editMenuGeneral(item)" data-bs-toggle="modal" data-bs-target="#modal-menu-general" class="lw-list-drag-item-btn-collapse" href="javascript:">
						<i class="fa-solid fa-pen"></i>
					</a>
					<a @click="deleteMenuGeneral(index)" class="lw-list-drag-item-btn-collapse" href="javascript:">
						<i class="fa-regular fa-trash-can"></i>
					</a>
				</div>
			</div>
			<div class="collapse" :id="`collapse-general-${index}`">
				<h6 class="text-secondary font-main text-opacity-75 d-flex align-items-center justify-content-between mt-3">
					<span>
						<i class="fa-solid fa-arrow-down color-secondary me-1"></i>
						Principal
					</span>
					<span>
						<span :class="item.principal.length === 15 ? 'color-red' : 'color-green' ">					
							{{ item.principal.length }}/15
						</span>
						<button @click="addMenuPrincipal(item)" :disabled="item.principal.length >= 15" type="button" class="btn btn-custom-inline-gray mw-100 ms-2">
							<i class="fa-solid fa-plus me-1 color-main"></i>
							Agregar principal
						</button>
					</span>
				</h6>
				<MenuCustomPrincipal 
					:menuPrincipal="item.principal" 
					:color="item.color_activo" 
					:errorForm="errorForm" 
					:indexMenuGeneral="index" 
				/>
			</div>
		</div>
	</draggable>
</template>