<script>
import MenuCustomGeneral from '@/components/customization/menuCustomGeneral.vue'
import ModalMenuGeneral from '@/components/customization/modalMenuGeneral.vue'
import { mapActions, mapState } from "vuex";

export default {
	components: {
		MenuCustomGeneral,
		ModalMenuGeneral
	},
	data() {
		return {
			//Valid
			errorForm: false,
			validForm: false,
			//Counters
			countErrorPrincipal: 0,
			countErrorSecondary: 0,
			countErrorTertiary: 0,
			drag: false,
			loading: false,
			loadingModal: false,
			menuCustom: []
		}
	},
	computed: {
		...mapState("customizationModule", ["listaModulos", "menu"]),

		maxMenuGeneralExceeded() {
			return this.menuCustom.length >= 5;
		}
	},

	watch: {
		menuCustom: {
			handler() {
				this.validMenuCustom();
			},
			deep: true
		},

		menu: {
			handler(newMenu) {
				this.menuCustom = newMenu;
			},
			deep: true

		}
	},

	mounted() {
		this.menuCustom = this.menu;
	},

	methods: {
		...mapActions("customizationModule", ["actualizarMenu", "cleanSelectedMenuGeneral"]),

		//Limpia el formulario del menú general.
		cleanMenuGeneral() {
			this.cleanSelectedMenuGeneral();
		},

		validMenuCustom() {
			// campos validables: nombre_elemento, url_externa
			const validPrincipal = [];
			const validSecondary = [];
			const validTertiary  = [];

			this.menuCustom.forEach(header => {
				header.principal.forEach(principal => {
					// Valida menús principales
					if (!principal.nombre_elemento || (!principal.url_externa && principal.secundarios.length === 0)) validPrincipal.push(false);
					else validPrincipal.push(true);
					principal.secundarios.forEach(secondary => {
						// Valida secundarios
						if (!secondary?.nombre_elemento || (!secondary?.url_externa && secondary?.terciarios.length === 0)) validSecondary.push(false);
						else validSecondary.push(true);
						secondary?.terciarios.forEach(tertiary => {
							// Valida terciarios
							if (!tertiary?.nombre_elemento || !tertiary?.url_externa) validTertiary.push(false);
							else validTertiary.push(true);
						});
					});
				});
			});

			// console.log("validPrincipal", validPrincipal);
			// console.log("validSecondary", validSecondary);
			// console.log("validTertiary", validTertiary);

			//Comprueba todos los campos de los dos array creados.
			if (validPrincipal.includes(false) || validSecondary.includes(false) || validTertiary.includes(false)) this.validForm = false;
			else {
				this.validForm = true;
				this.errorForm = false;
			}

			//Contador de errores.
			this.countErrorPrincipal = validPrincipal.filter(value => value === false).length;
			this.countErrorSecondary = validSecondary.filter(value => value === false).length;
			this.countErrorTertiary  = validTertiary.filter(value => value === false).length;
		},

		async saveMenuGeneral(menuGeneral) {
			try {
				this.loadingModal = true;
				if ( !menuGeneral.id_header ) {
					this.menuCustom.push({
						id_header: Symbol(),
						nombre_header: menuGeneral.nombre_header,
						color_activo: menuGeneral.color_activo,
						principal: [{
							id_elemento: Symbol(),
							nombre_elemento: '',
							url_externa: '',
							secundarios: []
						}]
					});
				} else {
					let index = this.menuCustom.findIndex(obj => obj.id_header === menuGeneral.id_header);
					this.menuCustom[index].nombre_header = menuGeneral.nombre_header;
					this.menuCustom[index].color_activo = menuGeneral.color_activo;
				}
				this.loadingModal = false;
				this.$refs.formModalMenuGeneral.closeModal();
				this.cleanMenuGeneral();
			} catch (error) {
				console.log(error);
			}
		},

		async saveCustomMenu() {
			if (!this.validForm) {
				this.errorForm = true;
				this.toastEditMenuInvalid();
				return;
			}

			this.loading = true;
			const resMenu = await this.actualizarMenu({ menu: this.preparePayload() });
			if (!resMenu) {
				this.toastEditMenuError();
			} else {
				this.toastEditMenuSuccess();
			}

			this.loading = false;
		},

		preparePayload() {
			const payloadMenu = [];
			this.menuCustom.forEach((generalEl, generalIdx) => {
				payloadMenu.push({
					id_header: typeof generalEl.id_header === 'symbol' ? undefined : generalEl.id_header, 
					nombre_header: generalEl.nombre_header, 
					color_activo: generalEl.color_activo, 
					orden: generalIdx, 
					principal: []
				});

				if (!generalEl.principal || generalEl.principal.length === 0) return;
				generalEl.principal.forEach((principalEl, principalIdx) => {
					payloadMenu[generalIdx].principal.push({
						id_elemento: typeof principalEl.id_elemento === 'symbol' ? undefined : principalEl.id_elemento, 
						nombre_elemento: principalEl.nombre_elemento, 
						url_externa: principalEl.url_externa,  
						orden: principalIdx, 
						secundarios: []
					});

					if (!principalEl.secundarios || principalEl.secundarios.length === 0) return;
					principalEl.secundarios.forEach((secondaryEl, secondaryIdx) => {
						payloadMenu[generalIdx].principal[principalIdx].secundarios.push({
							id_elemento: typeof secondaryEl.id_elemento === 'symbol' ? undefined : secondaryEl.id_elemento, 
							nombre_elemento: secondaryEl.nombre_elemento, 
							url_externa: secondaryEl.url_externa,  
							orden: secondaryIdx, 
							terciarios: []
						});

						if (!secondaryEl.terciarios || secondaryEl.terciarios.length === 0) return;
						secondaryEl.terciarios.forEach((tertiaryEl, tertiaryIdx) => {
							payloadMenu[generalIdx].principal[principalIdx].secundarios[secondaryIdx].terciarios.push({
								id_elemento: typeof tertiaryEl.id_elemento === 'symbol' ? undefined : tertiaryEl.id_elemento, 
								nombre_elemento: tertiaryEl.nombre_elemento, 
								url_externa: tertiaryEl.url_externa,  
								orden: tertiaryIdx, 
							});
						});
					});
				});
			});
			return payloadMenu;
		},

		deleteMenuGeneral(index) {
			this.menuCustom.splice(index, 1);
		},

		toastEditMenuInvalid() {
			this.$toast.open({
				message: "Hay datos inválidos. Por favor revisa y vuelve a intentar.",
				type: "error",
				duration: 5000,
				position: "top-right",
			});
		},
		toastEditMenuError() {
			this.$toast.open({
				message: "Error en el envío de información. Por favor recarga la página e intenta nuevamente.",
				type: "error",
				duration: 5000,
				position: "top-right",
			});
		},
		toastEditMenuSuccess() {
			this.$toast.open({
				message: "Se ha editado la configuración de menú exitosamente.",
				type: "success",
				duration: 5000,
				position: "top-right"
			});
		},
	},
}
</script>

<template>
	<form @submit.prevent="saveCustomMenu">
		<h5 class="mt-3 mt-lg-0 mb-0 d-flex flex-wrap justify-content-between align-items-center font-main text-secondary">
			<span>
				<i class="fa-solid fa-up-right-from-square color-main"></i>
				Menú personalizado
			</span>
			<button @click="cleanMenuGeneral()" type="button" :disabled="maxMenuGeneralExceeded" data-bs-toggle="modal" data-bs-target="#modal-menu-general" class="btn btn-custom-color-blue rounded-pill mw-100 my-2 my-md-0">
				<i class="fa-solid fa-plus me-1"></i>
				Crear menú
			</button>
		</h5>
		<h6 class="font-main text-secondary border-bottom border-color mb-3 pb-3 text-opacity-50">
			Crea y configura tu menú personalizado. Podrás crear hasta 5 secciones diferentes.
		</h6>
		<div class="row">
			<div class="col-12">
				<h6 class="text-secondary font-main text-opacity-75 d-flex justify-content-between">
					<span>
						<i class="fa-solid fa-arrow-down color-secondary me-1"></i> General
					</span>
					<span>
							<span v-if="errorForm && countErrorPrincipal !== 0 || errorForm && countErrorSecondary !== 0 || errorForm && countErrorTertiary !== 0" class="color-red me-2">
								<i class="fa-solid fa-circle-arrow-down"></i>
								{{ countErrorPrincipal + countErrorSecondary + countErrorTertiary }}
								<span v-if="countErrorPrincipal === 1 || countErrorSecondary === 1 || countErrorTertiary === 1">Error</span>
								<span v-if="countErrorPrincipal > 1 || countErrorSecondary > 1 || countErrorTertiary > 1">Errores</span>
							</span>
							<span :class="menuCustom.length > 5 ? 'color-red' : 'color-green' "> {{ menuCustom.length }}/5 </span>
					</span>
				</h6>
				<MenuCustomGeneral :menu="menuCustom" :errorForm="errorForm" @deleteMenuGeneral="deleteMenuGeneral" />
			</div>
		</div>
		<ModalMenuGeneral ref="formModalMenuGeneral" :loading="loadingModal" @saveMenuGeneral="saveMenuGeneral" />
		<div class="d-flex justify-content-end mt-3">
			<button type="submit" :disabled="errorForm" class="btn btn-custom-color-blue rounded-pill mw-100">
				<span v-if="loading">
					<span width="20" class="spinner-border spinner-border-sm text-light opacity-50"></span>
					Guardando...
				</span>
				<span v-else>
					Guardar
				</span>
			</button>
		</div>
	</form>
</template>

