
<script>
import { mapState } from "vuex";
export default {
	props: {
		loading: {
			type: Boolean,
			required: false
		}
	},
	data() {
		return {
			colores: ['#0072F7','#F26F28','#6C6CFF','#4BBE61','#E64141','#52CEC1','#F6C700','#EC206B'],
		}
	},

	computed: {
		...mapState("customizationModule", ["selectedMenuGeneral"]),

		isDataValid() {
			const validation = {
				nombre_header: !!this.selectedMenuGeneral.nombre_header,
				color_activo: !!this.selectedMenuGeneral.color_activo,
			};
			return Object.keys(validation).every(function(keys) {
				return validation[keys]
			});
		},
	},

	methods: {
		closeModal() {
			this.$refs.closeModal.click();
		},
		save() {
			this.$emit('saveMenuGeneral', this.selectedMenuGeneral);
		},
		captureColor(color) {
			this.selectedMenuGeneral.color_activo = color;
		},
	}
}
</script>

<template>
	<div 
		id="modal-menu-general"
		class="modal" 
		tabindex="-1"
	>
		<div class="modal-dialog modal-dialog-centered">
			<form 
				class="modal-content"
				@submit.prevent="save"
			>
				<div class="modal-header">
					<h5 class="modal-title text-secondary">
						<i class="fa-solid fa-up-right-from-square color-secondary me-1"></i>
						Menú General
					</h5>
					<button 
						ref="closeModal"
						type="button" 
						class="modal-custom-close"
						data-bs-dismiss="modal" 
						aria-label="Close">
						<i class="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 mb-3">
							<label class="text-secondary">Nombre</label>
							<input 
								type="text" 
								v-model.trim="selectedMenuGeneral.nombre_header"
								class="form-control input-custom" 
								maxlength="50"
								placeholder="Ingresa el nuevo nombre"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<h6>Selecciona un color para el menú</h6>
							<div class="card card-body bg-light border-0">
								<div class="d-flex flex-wrap gap-3">
									<a 
										v-for="(color, index) in colores" :key="index"
										@click="captureColor(color)"
										href="javascript:"
										class="square-color"
										:style="`background-color: ${color}`"
									>
										<span v-if="selectedMenuGeneral.color_activo === color">
											<i class="fa-solid fa-check square-color-icon"></i>
										</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button 
						type="button" 
						ref="closeModal"
						class="btn btn-custom-color-white rounded-pill mw-100" 
						data-bs-dismiss="modal">
						<i class="fa-solid fa-angle-left pe-1"></i>
						Volver
					</button>
					<button 
						type="submit" 
						:disabled="!isDataValid"
						class="btn btn-custom-color-blue rounded-pill mw-100"
					>
						<span v-if="loading">
							<span width="20" class="spinner-border spinner-border-sm text-light opacity-50"></span>
							Guardando...
						</span>
						<span v-if="!loading">
							Guardar
						</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</template>