<script>
import draggable from 'vuedraggable'
import MenuCustomSecondary from '@/components/customization/menuCustomSecondary.vue'

export default {
	components: {
		draggable,
		MenuCustomSecondary
	},
	props: {
		menuPrincipal: {
			type: Array,
			required: false
		},
		indexMenuGeneral: {
			type: Number,
			required: false
		},
		errorForm: {
			type: Boolean,
			required: false
		},
		color: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			count: 0
		}
	},
	computed: {
		dragOptions() {
			return {
				animation: 200,
				ghostClass: "ghost"
			};
		},
	},
	methods: {
		addMenuSecondary(item) {
			item.secundarios.push({
				id_elemento: Symbol(),
				nombre_elemento: '',
				url_externa: '',
				terciarios: []
			});
		},
		deleteMenuPrincipal(index) {
			this.menuPrincipal.splice(index, 1);
		},
		blockDeleteMenuPrincipal() {
			if(this.menuPrincipal.length === 1) return true;
		},
	}
}
</script>
<template>
	<draggable 
		class="lw-list-drag lw-list-drag-scroll-max mb-3 p-3" 
		:style="`cursor: auto; border: 2px solid; border-color: ${color}4D`"
		:list="menuPrincipal" 
		group="principal" 
		@start="drag = true"
		@end="drag = false"
		v-bind="dragOptions"
	>
		<div v-for="(item, index) in menuPrincipal" :key="item.id_elemento" class="lw-list-drag-container">
			<div class="lw-list-drag-item justify-content-between">
				<div class="d-flex align-items-center w-100 me-2">
					<div class="flex-shrink-0">
						<i class="fa-solid fa-grip-vertical lw-list-drag-item-icon me-2"></i>
					</div>
					<div class="flex-grow-1 lh-1 d-flex align-items-center w-100">
						<a 
							class="lw-list-drag-item-btn-collapse me-2 collapsed"
							href="javascript:"
							data-bs-toggle="collapse" 
							:data-bs-target="`#collapse-principal-${indexMenuGeneral}-${index}`"
						>
							<i class="fa-solid fa-angle-down lw-list-drag-item-btn-collapse-icon"></i>
						</a>
						<div class="d-flex align-items-center gap-3 w-100">
							<input 
								type="text" 
								v-model="item.nombre_elemento"
								class="form-control input-custom input-custom-small input-custom-inset-shadow flex-grow-1" 
								:class="item.nombre_elemento === '' && errorForm ? 'is-invalid' : '' "
								placeholder="Nombre menú principal"
							>
							<i class="fa-solid fa-angle-right"></i>
							<input 
								v-if="item.secundarios.length !== 0"
								:disabled="true"
								type="text" 
								class="form-control input-custom input-custom-small input-custom-inset-shadow flex-grow-1" 
								placeholder="Sin URL"
							>
							<input 
								v-else
								type="text" 
								v-model.trim="item.url_externa"
								class="form-control input-custom input-custom-small input-custom-inset-shadow flex-grow-1"
								:class="item.url_externa === '' && errorForm ? 'is-invalid' : '' "
								placeholder="URL"
							>
						</div>
					</div>
				</div>
				<div class="d-flex align-items-center gap-2">
					<button 
						:disabled="blockDeleteMenuPrincipal()"
						type="button"
						@click="deleteMenuPrincipal(index)"
						class="lw-list-drag-item-btn-collapse"
						href="javascript:"
					>
						<i class="fa-regular fa-trash-can"></i>
					</button>
				</div>
			</div>
			<div class="collapse" :id="`collapse-principal-${indexMenuGeneral}-${index}`" >
				<h6 class="text-secondary font-main text-opacity-75 d-flex align-items-center justify-content-between mt-3">
					<span>
						<i class="fa-solid fa-arrow-down color-secondary me-1"></i>
						Secundario
					</span>
					<span>
						<span :class="item.secundarios.length === 5 ? 'color-red' : 'color-green' ">					
							{{ item.secundarios.length }}/5
						</span>
						<button 
							@click="addMenuSecondary(item)"
							:disabled="item.secundarios.length >= 5"
							type="button" 
							class="btn btn-custom-inline-gray mw-100 ms-2"
						>
							<i class="fa-solid fa-plus me-1 color-main"></i>
							Agregar secundario
						</button>
					</span>
				</h6>
				<MenuCustomSecondary
					:menuSecundario="item.secundarios"
					:errorForm="errorForm"
					:indexMenuPrincipal="index"
					:indexMenuGeneral="indexMenuGeneral"
				/>
			</div>
		</div>
	</draggable>
</template>
